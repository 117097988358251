import GenericDAO from './../generic-dao'
import axios from 'axios'

const getUFs = () => GenericDAO.find(`/global/estado/`)
const getMunicipiosByUF = (idUF) => GenericDAO.find(`/global/cidade/`, { ds_uf: idUF })
const getMunicipiosEstadoId = (idUF) => GenericDAO.find(`/global/cidade/`, { cd_estado: idUF })
const getCEP = (cep) => GenericDAO.find(`/global/busca-cep/`, { cep }, { ignorarEstabelecimento: true })
const getCEPCidade = (dataSend) => GenericDAO.find(`/global/busca-cep-cidade/`, dataSend, { ignorarEstabelecimento: true })
const getCepViaCep = (cep) => axios.get(`https://viacep.com.br/ws/${cep}/json/`)

export default {
    getUFs,
    getMunicipiosByUF,
    getMunicipiosEstadoId,
    getCEP,
    getCEPCidade,
    getCepViaCep
}
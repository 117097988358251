import axios from 'axios'
import configs from '../configs'
import GenericDAO from './../generic-dao'

const failed = new Promise(resolve => { resolve(false) })

const findAll = (params, _configs) => GenericDAO.find(`/medclub/guias-eletronicas/`, params, _configs ? _configs : null)
const save = (dataSend) => GenericDAO.save(`/medclub/guias-eletronicas/`, dataSend)

const cancelarGuia = (dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.post(
        `${ configs.baseUrl }/medclub/financeiro/executar-guias/`, dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const consultarGuia = (id) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.get(
        `${ configs.baseUrl }/medclub/financeiro/consultar-guia/${id}/`, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const validarGuia = (id, hash) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    let params = {}
    params['cd_estabelecimento'] = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id
    return axios.post(
        `${ configs.baseUrl }/medclub/financeiro/validar-guia/?cd_estabelecimento=${params.cd_estabelecimento}`, { id, hash }, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const executarGuias = (dataSend) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    return axios.post(
        `${ configs.baseUrl }/medclub/financeiro/executar-guias/`, dataSend, { headers: {
            'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        } }
    )
    .then(response => response)
    .catch(configs.errorHandler)
}

const getAnexos = (id, hash, params) => {
    if (! params) params = {}
    params.cd_transacao_financeira = id
    params.ds_hash = hash
    return GenericDAO.find(`/webcli/anexo/`, params)
}

const consultarLaudo = (id) => {
    return GenericDAO.find(`/appuse/resultado-exame-shift/${id}/`)
}

const reenviarExamesShift = (dataSend) => {
    return GenericDAO.save(`/medclub/financeiro/reenviar-exames/`, dataSend)
    // let usuario = JSON.parse(localStorage.getItem('usuario'))
    // if (!usuario) return failed
    // return axios.post(
    //     `${ configs.baseUrl }/medclub/financeiro/executar-guias/`, dataSend, { headers: {
    //         'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
    //     } }
    // )
    // .then(response => response)
    // .catch(configs.errorHandler)
}


export default { validarGuia, executarGuias, consultarGuia, findAll, save, cancelarGuia, getAnexos, consultarLaudo, reenviarExamesShift }

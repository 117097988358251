import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/pessoa-juridica/`, params)
const find = (id) => GenericDAO.find(`/medclub/pessoa-juridica/${ id }/`)
const findAllCnae = (params) => GenericDAO.find(`/medclub/cnae`, params)
const remove = (id) => GenericDAO.remove(`/medclub/pessoa-juridica/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/pessoa-juridica/`, dataSend)
const findAllContatos = (params) => GenericDAO.find(`/medclub/pessoa-juridica-contatos/`, params)
const saveContato = (dataSend) => GenericDAO.save(`/medclub/pessoa-juridica-contatos/`, dataSend)
const removeContato = (id) => GenericDAO.remove(`/medclub/pessoa-juridica-contatos/${id}/`)
const findAllBancos = (params) => GenericDAO.find(`/medclub/pessoa-juridica-conta-bancaria/`, params)
const saveBanco = (dataSend) => GenericDAO.save(`/medclub/pessoa-juridica-conta-bancaria/`, dataSend)
const removeBanco = (id) => GenericDAO.remove(`/medclub/pessoa-juridica-conta-bancaria/${id}/`)
const saveEndereco = (dataSend) => GenericDAO.save(`/medclub/pessoa-juridica-endereco/`, dataSend)

export default {
    find,
    findAll,
    remove,
    save,
    findAllContatos,
    saveContato,
    removeContato,
    findAllBancos,
    saveBanco,
    removeBanco,
    saveEndereco,
    findAllCnae
}
